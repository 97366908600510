import React,{ useState } from 'react';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Petroleumbanner from '../images/data-banner.webp';
import DataCons1213 from "../images/data_cons_1213.webp";
import MetaTags from '../MetaTags';
import faq from '../images/frequently-asked-questions-solution-concept.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import '../Stlye.css';
const Realestateconsulting = () => {
  const [openIndex, setOpenIndex] = useState(null);
      const faqData = [
        {
          question: 'What is data science, and why do I need it?',
          answer: 'Data science is a multidisciplinary field that uses scientific methods, processes, algorithms, and systems to extract knowledge and insights from structured and unstructured data. It combines principles from statistics, computer science, domain expertise, and machine learning to analyze and interpret complex data sets.',
        },
        {
          question: 'How do you ensure the security and compliance of our data?',
          answer: "We follow industry best practices and implement robust data governance frameworks to ensure the security, integrity, and compliance of your data. This includes measures such as access controls, encryption, and regular audits to mitigate risks and maintain compliance with relevant regulations.",
        },
        {
          question: 'Can you work with our existing data infrastructure and systems?',
          answer: "Yes, we have extensive experience working with a wide range of data infrastructure and systems. Whether you're using legacy on-premises systems or modern cloud-based platforms, our team can seamlessly integrate with your existing environment to deliver solutions that meet your needs.",
        },
        {
          question: 'How long does a typical data science project take?',
          answer: 'The duration of a data science can vary depending on the scope and complexity of the requirements. We work closely with our clients to define clear project timelines and milestones, ensuring timely delivery while maintaining the highest quality standards.',
        },
        {
          question: 'What kind of ROI can we expect from investing in data science?',
          answer: "The ROI of data science can vary depending on factors such as the nature of your business, the scope of the project, and the specific goals you're looking to achieve. However, businesses typically see significant returns in terms of improved decision-making, cost savings, and revenue growth resulting from more effective use of data. ",
        },
        // Add more FAQ items as needed
      ];
      const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
  return (
    <div>
       <MetaTags
        title="Data Science Service Queensland, Australia | Smart Resources QLD"
        description="Make smarter decisions with data. Smart Resources QLD offers data science services to transform your Queensland business."
        keywords="Data Science Service Queensland, Australia"
        rel="canonical" href="https://smartresourcesqld.com.au/data-science"
        identifyUrl="https://smartresourcesqld.com.au/data-science"
        DCTitle="Data Science Service Queensland, Australia | Smart Resources QLD"
        DCDescription="Make smarter decisions with data. Smart Resources QLD offers data science services to transform your Queensland business."
        DCSubject="Data Science Service Queensland, Australia"
        Canonical="https://smartresourcesqld.com.au/data-science"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Data Science Service Queensland, Australia | Smart Resources QLD"
        ogDescription="Make smarter decisions with data. Smart Resources QLD offers data science services to transform your Queensland business."
        ogUrl="https://smartresourcesqld.com.au/data-science"
        property="og:type" content="website"
      />
      <section className="innr_banner" style={{ 
      backgroundImage: `url(${Petroleumbanner})` }}>
        <div className="page_title_innr">
          <div className="page_title">Data Science</div>
          <ul className="bread_crumb">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/our-operations">Services</NavLink></li>
            <li>Data Science</li>
          </ul>
        </div>
      </section>
      <section className="pricing servinnrbg">
        <div className="servinnng_middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="global_left serv_innr_heading">
                  <h1>Data Science</h1>
                  <p>Unlock the full potential of your data with Smart Resources QLD's comprehensive data science. In today's rapidly evolving business landscape, data has become the cornerstone of informed decision-making and competitive advantage. Our team of experienced data scientists is dedicated to helping businesses harness the power of their data to drive growth, innovation, and efficiency.<br/><br/>
                  <p style={{ fontWeight: 'bolder', marginBottom: '-20px', }}>Our Approach</p> <br/>At Smart Resources QLD, we understand that every business is unique, with its own set of challenges and opportunities. That's why we take a customized approach to data consulting, tailoring our services to meet the specific needs and goals of each client. Whether you're looking to optimize your data infrastructure, develop advanced analytics capabilities, or implement cutting-edge machine learning solutions, our team has the expertise and experience to deliver results.<div className="col-lg-6 col-md-6 col-sm-12">
             
              </div></p>
              
                </div>
              </div>
              
            
            </div>
          </div>
        </div>
        <div className="discuss-seo seo_price_bg">
        <div className="container">
      <div className="row discuss-ppc-seo">
      <h2 style={{ color: 'white' }}>
      Ready to Harness the Power of Data?
    </h2>
        <div className="button-ppc-new">
          <NavLink className="btn-trans" to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Request Demo</NavLink>
          {/* <a className="btn-trans" href="https://www.dubseo.co.uk/seo-services-packages-and-prices-london">Check Out Our Price</a> */}
        </div>
      </div>
    </div>
</div>

        <section className="include_services">
        <div className='include_service_innr'>
        <Container>
        <Row>

        <Col lg="4" md="6" sm="12">
          <div className='include_img'><img src={Petroleumbanner} alt="" className="img-fluid" /></div>
        </Col>

        <Col lg="8" md="6" sm="12">
        <div className='include_text'>
      <h3>Our Services</h3>
      {/* <img src={line} alt="Your Image" /> */}
      <div className='pf'>
      <ul>
        <li>
        <strong> Data Strategy Development:  </strong>We work closely with your team to develop a comprehensive data strategy aligned with your business objectives.
        </li>
        <li>
        <strong> Data Governance and Compliance:  </strong> Ensure the integrity, security, and compliance of your data with robust governance frameworks.
        </li>
        <li>
        <strong> Data Analytics and Visualization:</strong> Gain valuable insights from your data through advanced analytics techniques and intuitive visualization tools.
        </li>
        <li>
        <strong>Machine Learning and AI:</strong> Leverage the power of machine learning and artificial intelligence to uncover patterns, predict trends, and automate processes.
        </li>
        <li>
        <strong>Data Integration and Migration:</strong> Seamlessly integrate disparate data sources and migrate to modern, cloud-based platforms for improved accessibility and scalability.

        </li>
      </ul>
      </div>
      </div>
    </Col>

  </Row>
  
</Container>


    </div>
      <div className='include_service_innr'>
        <Container>
        <Row>
        <Col lg="8" md="6" sm="12" className='include_textpartts'>
        <div className='include_text'>
            <h3>Why Choose Smart Resources QLD?</h3>
            {/* <img src={line} alt="Your Image" /> */}
            <div className='pf'>
              <ul>
                <li>
                  <strong>Expertise: </strong>Our team brings a wealth of experience and expertise in data consulting, spanning industries and technologies.
                </li>
                <li>
                  <strong>Customized Solutions:</strong> We understand that one size does not fit all. Our solutions are tailored to your unique business needs and goals.
                </li>
                
                <li>
                  <strong>Proven Track Record:</strong> We have a proven track record of delivering tangible results for our clients, helping them unlock the full potential of their data.
                </li>
                <li>
                  <strong>Collaborative Approach:</strong> We believe in building strong, collaborative partnerships with our clients, working together to achieve success.
                </li>
                <li>
                  <strong>Continuous Support:</strong> Our commitment to your success doesn't end with the project. We provide ongoing support and guidance to ensure long-term value and impact.
                </li>
              </ul>

              <p>We also provide our Data Science services in <NavLink to="/data-science/cairns" onClick={() => window.scrollTo(0, 0)}>Cairns</NavLink>, <NavLink to="/data-science/rockhampton" onClick={() => window.scrollTo(0, 0)}>Rockhampton</NavLink> and <NavLink to="/data-science/townsville" onClick={() => window.scrollTo(0, 0)}>Townsville</NavLink></p>
            </div>
            </div>
          </Col>

          <Col lg="4" md="6" sm="12" className='include_imgpartts'>
            <div className='include_img right'>
              <img src={DataCons1213} alt="" />
            </div>
          </Col>
        </Row>
        </Container>
     
      </div>
    <div className="faqContainer">
    <Container>
    <Row>
    <Col lg="8" md="6" sm="12" className='accordion_left'>
        <div className="accordion">
        <h1>Frequently Asked Questions</h1>
          {faqData.map((item, index) => (
            <div key={index} className="accordion-item">
              <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                <h2>{item.question}</h2>
                {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
              </div>
              {openIndex === index && <div  style={{ fontSize: '18px' }}className="accordion-answer">{item.answer}</div>}
            </div>
          ))}
        </div>
        </Col>
        <Col lg="4" md="6" sm="12" className='accordion_right'>
      <div className="faq_Image">
        {/* Place your image here */}
        <div style={{ position: 'relative' }}>
      <img
        src={faq}
        className="img-fluid"
        alt=""
      />
      
    </div>
    </div>
    </Col>
    </Row>
      </Container>
    </div>

          {/* <div className="custom_resoueces_arrow">
          <div className="pp89">
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
          <div className="nn89">
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div> */}
        </section>
        
      </section>
    </div>
  );
};

export default Realestateconsulting ;
