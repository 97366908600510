import React,{ useState } from 'react';
import { NavLink } from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Petroleumbanner from '../images/real-estate-banner.webp';
import faq from '../images/frequently-asked-questions-solution-concept.webp';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import '../Stlye.css';
import MetaTags from '../MetaTags';
import ser from '../images/services1jpg.webp';
const Realestateconsulting = () => {
  const [openIndex, setOpenIndex] = useState(null);
      const faqData = [
        {
          question: 'What are the benefits of hiring a real estate consultant?',
          answer: 'Hiring a real estate consultant provides you with access to expert knowledge, insights, and guidance, helping you navigate complex real estate transactions and make informed decisions.',
        },
        {
          question: 'How much do real estate consulting services cost?',
          answer: 'The cost of real estate consulting services varies depending on the scope of the project, the level of expertise required, and other factors. Contact us for a personalized quote based on your specific needs',
        },
        {
          question: 'What is included in a market analysis?',
          answer: 'A market analysis typically includes an assessment of current market conditions, trends, demographics, competition, and forecasts, providing valuable insights into the local real estate market.',
        },
        {
          question: 'How do you determine property valuation?',
          answer: 'Property valuation is determined based on various factors, including location, size, condition, comparable sales, rental income, and market demand. Our expert appraisers use industry-standard methods to ensure accuracy and reliability.',
        },
        {
          question: 'Do you provide consulting services for commercial real estate?',
          answer: 'Yes, we offer consulting services for residential, commercial, and industrial real estate properties. Our team of consultants has extensive experience across various sectors and can tailor our services to meet your specific needs.',
        },
        // Add more FAQ items as needed
      ];
      const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
  return (
    <div>
       <MetaTags
        title="Real Estate | Consulting in Queensland | Smart Resources QLD"
        description="Trusted real estate consulting company in Queensland. We offers expert services to help clients succeed with top strategies from a leading real estate agency."
        keywords="Real Estate Consulting Services in Queensland, Australia"
        name="robots" content="index, follow" 
        rel="canonical" href="https://smartresourcesqld.com.au/real-estate-consulting"
        identifyUrl="https://smartresourcesqld.com.au/real-estate-consulting"
        DCTitle="Real Estate | Consulting in Queensland | Smart Resources QLD"
        DCDescription="Trusted real estate consulting company in Queensland. We offers expert services to help clients succeed with top strategies from a leading real estate agency."
        DCSubject="Real Estate Consulting Services in Queensland, Australia"
        Canonical="https://smartresourcesqld.com.au/real-estate-consulting"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate | Consulting in Queensland | Smart Resources QLD"
        ogDescription="Trusted real estate consulting company in Queensland. We offers expert services to help clients succeed with top strategies from a leading real estate agency."
        ogUrl="https://smartresourcesqld.com.au/real-estate-consulting"
      />
      <section className="innr_banner" style={{ 
      backgroundImage: `url(${Petroleumbanner})` }}>
        <div className="page_title_innr">
          <div className="page_title">       Real Estate Consulting  </div>
          <ul className="bread_crumb">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/our-operations">Services</NavLink></li>
            <li>Real Estate Consulting</li>
          </ul>
        </div>
      </section>
      <section className="pricing servinnrbg">
        <div className="servinnng_middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="global_left serv_innr_heading">
                  <h1> Real Estate Consulting Services </h1>
                  <p>Welcome to Smart Resources QLD, your trusted partner in navigating the complex world of real estate. With years of experience and a team of expert consultants, we offer comprehensive real estate consulting services tailored to meet your specific needs.<br/><br/> Whether you're a first-time homebuyer, an experienced investor, or a business owner looking for commercial space, our consulting services are designed to provide you with the knowledge, insights, and strategies necessary to make informed decisions and achieve your real estate goals.<br/><br/>
Our team of consultants is comprised of industry professionals with extensive experience in residential, commercial, and industrial real estate markets. We stay abreast of the latest market trends, regulations, and developments to ensure that our clients receive the most accurate and up-to-date information.<div className="col-lg-6 col-md-6 col-sm-12">
             
              </div></p>
              
                </div>
              </div>
              
            
            </div>
          </div>
        </div>
        <div className="discuss-seo seo_price_bg">
        <div className="container">
      <div className="row discuss-ppc-seo">
        <h2>Ready to Elevate Your Real Estate Strategy?</h2>
        <div className="button-ppc-new">
          <NavLink className="btn-trans" onClick={() => window.scrollTo(0, 0)} to="/contact-us">Schedule Consultation</NavLink>
        </div>
      </div>
    </div>
</div>

        <section className="include_services">
        <div>
        <div className="container">
  <Row>

    <Col lg="4" md="6" sm="12">
      <div className='include_img'><img src={ser} alt="" className="img-fluid" /></div>
    </Col>

    <Col lg="8" md="6" sm="12">
      <div className='include_text'>
      <h3>Our Real Estate Consulting Services Include:</h3>
      {/* <img src={line} alt="Your Image" /> */}
      <div className='pf'>
      <ul >
        <li>
          <strong>Market Analysis:</strong> We conduct thorough market analyses to assess current market conditions, trends, and forecasts, helping you identify opportunities and mitigate risks.
        </li>
        <li>
          <strong>Investment Strategy:</strong> Whether you're looking to buy, sell, or invest in real estate, we develop customized investment strategies tailored to your financial goals and risk tolerance.
        </li>
        <li>
          <strong>Property Valuation:</strong> Our expert appraisers provide accurate property valuations based on comprehensive market research and analysis, ensuring that you make informed decisions regarding pricing and negotiations.
        </li>
        <li>
          <strong>Due Diligence:</strong> We perform detailed due diligence investigations to evaluate the legal, financial, and physical aspects of real estate transactions, safeguarding your interests and minimizing potential liabilities.
        </li>
        <li>
          <strong>Negotiation Support:</strong> Our skilled negotiators advocate on your behalf during purchase, sale, or lease negotiations, striving to achieve favorable terms and maximize value.
        </li>
      </ul>
      </div>
      </div>
    </Col>

  </Row>
  <p className='f'>At Smart Resources QLD, we are committed to providing you with personalized, professional, and reliable real estate consulting services that empower you to make confident and successful real estate decisions. Contact us today to learn more about how we can help you achieve your real estate goals.</p>

  <p>We also provide our Real Estate Constulting services in <NavLink to="/realestate-consulting/cairns" onClick={() => window.scrollTo(0, 0)}>Cairns</NavLink>, <NavLink to="/realestate-consulting/rockhampton" onClick={() => window.scrollTo(0, 0)}>Rockhampton</NavLink> and <NavLink to="/realestate-consulting/townsville" onClick={() => window.scrollTo(0, 0)}>Townsville</NavLink></p>
</div>


    </div>
    <div className="faqContainer">
      <Container>
        <Row>
          <Col lg="8" md="6" sm="12" className='accordion_left'>
            <div className="accordion">
            <h1>Frequently Asked Questions</h1>
              {faqData.map((item, index) => (
                <div key={index} className="accordion-item">
                  <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                    <h2>{item.question}</h2>
                    {openIndex === index ? <AiOutlineUp /> : <AiOutlineDown />}
                  </div>
                  {openIndex === index && <div className="accordion-answer">{item.answer}</div>}
                </div>
              ))}
            </div>
          </Col>
          <Col lg="4" md="6" sm="12" className='accordion_right'>
            {/* Place your image here */}
            <div className='faq_Image'>
          <img
            src={faq}
            className="img-fluid"
            alt=""
          />
          
        </div>
          </Col>
        </Row>
      </Container>
    </div>

          {/* <div className="custom_resoueces_arrow">
          <div className="pp89">
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
          <div className="nn89">
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div> */}
        </section>
        
      </section>
    </div>
  );
};

export default Realestateconsulting ;
